<template>
  <div class="user-contact">
    <v-alert text prominent v-if="!user" type="error" icon="$vuetify.icons.cloudAlert">{{ $t("NotSignedIn") }}</v-alert>

    <v-form ref="form" v-model="form" v-if="user" lazy-validation>
      <v-card-text class="pb-0 pt-6">
        <v-text-field
          :label="$t('Subject')"
          outlined
          :rules="[v => !!v || $t('SubjectIsMissing')]"
          v-model="item.subject"
          :loading="is_saving" 
          maxlength="75"
          required
        ></v-text-field>
        <v-textarea
          :loading="is_saving"
          outlined
          required
          :rules="[v => !!v || $t('MessageIsMissing')]"
          no-resize
          v-model="item.message"
          :label="$t('Message')"
        ></v-textarea>
        <error-box :error="error"></error-box>

        <v-alert
          text
          prominent
          v-if="is_success"
          type="success"
          icon="$vuetify.icons.farCheckCircle"
        >{{ $t("Success") }}</v-alert>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="send" :loading="is_saving" class="text-none">{{ $t("Button.Send") }}</v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<i18n>
    {
    "en": {
    "Contact": "Contact",
    "Message": "Message",
    "Subject": "Subject",
    "SubjectIsMissing": "Subject is missing!",
    "MessageIsMissing": "Message is missing!",
    "Reciver": "Reciver",
    "ReciverIsMissing": "Reciver is missing",
    "Success": "Message was sent!",
    "NotSignedIn": "You must be logged in to send a message!"
    },
    "sv": {
    "Contact": "Kontakta",
    "Message": "Meddelande",
    "Subject": "Ämne",
    "SubjectIsMissing": "Ämne saknas",
    "MessageIsMissing": "Meddelande saknas!",
    "Reciver": "Mottagare",
    "ReciverIsMissing": "Mottagare saknas",
    "Success": "Meddelandet skickades!",
    "NotSignedIn": "Du måste vara inloggad för att kunna skicka ett meddelande!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "user_contact",
  props: ["profile"],

  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  metaInfo() {
    return this.getMeta();
  },
  data: () => ({
    item: {
      subject: "",
      message: ""
    },
    is_saving: false,
    is_success: false,
    error: null,
    form: false
  }),
  methods: {
    ...mapActions({
      create: "account/messages/create"
    }),

    send() {
      var self = this;
      self.error = null;
      self.is_success = false;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .create({ reciver: self.profile.username, message: self.item })
          .then(function() {
            self.is_saving = false;
            self.is_success = true;
            self.item.subject = "";
            self.item.message = "";
            self.$refs.form.resetValidation()
          })
          .catch(function(error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    getMeta() {
      var obj = {
        title: this.$t("Contact")
      };
      return obj;
    }
  }
};
</script>