<template>
  <div class="user-following pb-15">
    <v-skeleton-loader
      :loading="is_loading"
      max-width="500"
      type="list-item-two-line"
    >
      <v-row>
        <template v-if="!error">
          <v-col v-for="item in items" :key="item.id" md="4" xs="12" cols="12">
            <diary-item :diary="item"></diary-item>
          </v-col>

          <v-col cols="12" class="text-center" v-if="unit_pages > 1">
            <v-pagination
              v-model="page"
              :length="unit_pages"
              :total-visible="7"
            ></v-pagination>
          </v-col>

          <v-col cols="12" v-if="items.length < 1" class="text-center pt-10">{{
            $t("NoFollowing")
          }}</v-col>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </v-row>
    </v-skeleton-loader>
  </div>
</template>

<i18n>
    {
    "en": {
    "Following": "Following",
    "NoFollowing": "No diaries to display!"
    },
    "sv": {
    "Following": "Följer",
    "NoFollowing": "Användaren följer inga dagböcker!"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "@/_services";
import DiaryItem from "@/components/global/DiaryItem.vue";

export default {
  components: { "diary-item": DiaryItem },
  name: "user_following",
  props: ["profile"],
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  metaInfo() {
    return this.getMeta();
  },
  data: () => ({
    items: [],
    unit_pages: 0,
    page: 1,
    is_loading: false,
    error: null,
    is_owner: false,
  }),
  created: function () {
    this.setFromUrl();
    this.loadData();
    this.is_owner = this.profile.username == this.user.username;
  },

  methods: {
    loadData() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      accountService
        .getFollowing(self.profile.username, self.page)
        .then(function (response) {
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.is_loading = false;
          self.error = error;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    getMeta() {
      var obj = {
        title: this.$t("Following"),
      };
      return obj;
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadData();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });

      self.$vuetify.goTo(0);
    },
  },
};
</script>