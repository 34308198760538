<template>
  <div class="fill-height">
    <v-container
      class="mt-10 pt-10 pb-5 mb-10 account-master mobile-padding-fix"
    >
      <v-skeleton-loader :loading="is_loading" height="700" type="card-avatar">
        <v-row class="mobile-margin-fix mt-0" v-if="!error">
          <v-col md="3" xs="12" cols="12">
            <div class="text-center">
              <user-avatar :user="profile" :size="150"></user-avatar>
            </div>
            <h2 class="text-center text-h6 pt-2 pl-2 pr-2">
              {{ profile.username | capitalize }}
            </h2>
            <div class="text-center grey--text text--darken-1 joined">
              {{ $t("Joined") + " " + getDate(profile.create_date) }}
            </div>
          </v-col>

          <v-col md="9" xs="12" cols="12">
            <template v-if="profile.has_access">
              <v-tabs active-class="active-menu" hide-slider optional>
                <v-tab class="text-none" :to="getMenuUrl(null)" exact-path>{{
                  $t("About")
                }}</v-tab>
                <v-tab
                  class="text-none"
                  :to="getMenuUrl('following')"
                  exact-path
                  >{{ $t("Following") }}</v-tab
                >
                <v-tab
                  class="text-none"
                  :to="getMenuUrl('contact')"
                  v-if="!is_admin"
                  >{{ $t("Contact") }}</v-tab
                >
              </v-tabs>

              <v-divider></v-divider>
              <br />
              <router-view
                :profile="profile"
                :key="$route.fullPath"
              ></router-view>
            </template>
            <template v-if="!profile.has_access">
              <v-card>
                <div class="text-center pb-10">
                  {{ $t("PrivateProfile") }}
                </div>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-skeleton-loader>
      <error-page :error="error" v-if="error" home="/"></error-page>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "About": "Diaries",
    "Following": "Following",
    "Contact": "Contact",
    "PrivateProfile": "This is a private profile!",
    "Joined": "Joined"
    },
    "sv": {
    "About": "Dagböcker",
    "Following": "Följer",
    "Contact": "Kontakt",
    "PrivateProfile": "Detta är en privat profil!",
    "Joined": "Blev medlem"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { accountService } from "../../_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import * as moment from "moment";
export default {
  name: "user_master",
  metaInfo() {
    return this.meta;
  },
  components: {
    "user-avatar": UserAvatar,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    is_loading: false,
    error: null,
    profile: {},
    meta: {},
    is_admin: false,
  }),
  created: function () {
    this.init();
  },
  methods: {
    init() {
      this.loadData(this.$route.params.username);
    },
    loadData(name) {
      var self = this;
      self.error = null;
      self.is_loading = true;

      accountService
        .getUserProfile(name)
        .then(function (response) {
          self.profile = response.data;
          self.is_loading = false;
          self.is_admin =
            self.user != null && self.user.username == self.profile.username;
          self.setMeta();
        })
        .catch(function (error) {
          self.is_loading = false;
          self.error = error;
        });
    },
    getMenuUrl(name) {
      if (!name) {
        return "/user/" + this.profile.username;
      }
      return "/user/" + this.profile.username + "/" + name;
    },
    getDate(date) {
      return moment(date).format("MMMM YYYY");
    },
    setMeta() {
      var self = this;

      this.meta = {
        title: "",
        titleTemplate:
          "%s - " + self.profile.username + " | " + process.env.VUE_APP_NAME,
        meta: [
          {
            property: "og:title",
            content: self.profile.username,
            vmid: "og:title",
          },
          {
            name: "description",
            content: self.profile.about,
            vmid: "description",
          },
          {
            property: "og:description",
            content: self.profile.about,
            vmid: "og:description",
          },

          {
            property: "og:image",
            content: self.$imagehelpers.accountAvatar(self.profile.avatar, 200),
            vmid: "og:image",
          },
          {
            property: "og:image:width",
            content: "1200",
            vmid: "og:image:width",
          },
          {
            property: "og:image:height",
            content: "300",
            vmid: "og:image:height",
          },
          {
            property: "twitter:image:src",
            content: self.$imagehelpers.accountAvatar(self.profile.avatar, 200),
            vmid: "twitter:image:src",
          },
        ],
      };
    },
    setLoadingMeta() {
      this.meta = {
        title: this.$route.params.username,
        titleTemplate: "%s - " + process.env.VUE_APP_NAME,
      };
    },
  },
  watch: {
    $route() {
      if (this.profile.username != this.$route.params.username) {
        this.init();
      }
    },
  },
};
</script>

<style scoped>
.joined {
  font-size: 14px;
}
</style>