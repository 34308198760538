<template>
  <v-card outlined :to="'/' + diary.name">
    <v-img :src="getBanner()" height="150px" class="grey lighten-2"></v-img>

    <v-card-title>
      {{ diary.title | capitalize }}
    </v-card-title>

    <v-card-subtitle> </v-card-subtitle>

    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    diary: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    getBanner() {
      if (!this.diary.banner) return null;
      return this.diary.banner.width_1200;
    },
  },
};
</script>