<template>
  <v-row>
    <v-col
      v-for="item in profile.diaries"
      :key="item.id"
      md="4"
      xs="12"
      cols="12"
    >
      <diary-item :diary="item"></diary-item>
    </v-col>
  </v-row>
</template>

<i18n>
    {
    "en": {
    "Dairies": "Dairies"
    },
    "sv": {
    "Dairies": "Dagböcker"
    }
    }
</i18n>

<script>
import DiaryItem from "@/components/global/DiaryItem.vue";

export default {
  name: "user_home",
  props: ["profile"],
  components: { "diary-item": DiaryItem },
  metaInfo() {
    return {
      titleTemplate: null,
      title:
        this.$t("Dairies") +
        " - " +
        this.profile.username +
        " | " +
        process.env.VUE_APP_NAME,
    };
  },
  methods: {},
};
</script>